export const map2 = [
  {
    code: 1,
    name_en: "Dammaj",
    name_ar: "دماج",
    latitude: 16.8912433,
    longitude: 43.7791221,
    map: 2
  },
  {
    code: 2,
    name_en: "Saada",
    name_ar: "صعدة",
    latitude: 16.9528695,
    longitude: 43.7146043,
    map: 2
  },
  {
    code: 3,
    name_en: "Amran",
    name_ar: "عمران",
    latitude: 15.6658275,
    longitude: 43.9164303,
    map: 2
  },
  {
    code: 4,
    name_en: "Sanaa",
    name_ar: "صنعاء",
    latitude: 15.359828,
    longitude: 44.1922583,
    map: 2
  },
  {
    code: 5,
    name_en: "Al Hudaydah",
    name_ar: "الحديدة",
    latitude: 14.7900282,
    longitude: 42.929635,
    map: 2
  },
  {
    code: 6,
    name_en: "Dhamar",
    name_ar: "ذمار",
    latitude: 14.5440286,
    longitude: 44.3866409,
    map: 2
  },
  {
    code: 7,
    name_en: "Ibb",
    name_ar: "إب",
    latitude: 13.9593877,
    longitude: 44.1440534,
    map: 2
  },
  {
    code: 8,
    name_en: "Al-Bayda",
    name_ar: "البيضاء",
    latitude: 13.9867297,
    longitude: 45.5566333,
    map: 2
  },
  {
    code: 9,
    name_en: "Marib",
    name_ar: "مأرب",
    latitude: 15.4747097,
    longitude: 45.3052453,
    map: 2
  },
  {
    code: 10,
    name_en: "Taiz",
    name_ar: "تعز",
    latitude: 13.5832662,
    longitude: 43.9806553,
    map: 2
  },
  {
    code: 11,
    name_en: "Lahj",
    name_ar: "لحج",
    latitude: 13.0589314,
    longitude: 44.8735285,
    map: 2
  },
  {
    code: 12,
    name_en: "Aden",
    name_ar: "عدن",
    latitude: 12.810289,
    longitude: 44.9805477,
    map: 2
  }
];

const map3 = [
  {
    code: 13,
    name_en: "Port of Aden",
    name_ar: "ميناء عدن",
    latitude: 12.7912357,
    longitude: 44.9833461,
    map: 3
  },
  {
    code: 14,
    name_en: "Port of Al-Hudaydah",
    name_ar: "ميناء الحديدة",
    latitude: 14.8344932,
    longitude: 42.9351543,
    map: 3
  }
];

const map4 = [
  {
    code: 15,
    name_en: "Taiz",
    name_ar: "تعز",
    latitude: 13.5832662,
    longitude: 43.9806553,
    map: 4
  }
];

const map12 = [
  {
    code: 16,
    name_en: "Al-Dalil bridge",
    name_ar: "جسر الدليل",
    latitude: 14.11250555,
    longitude: 44.21003197,
    map: 12
  },
  {
    code: 17,
    name_en: "Al-Sayani bridge",
    name_ar: "جسر السياني",
    latitude: 13.82968,
    longitude: 44.17241,
    map: 12
  },
  {
    code: 18,
    name_en: "Bab Makhaleh (Bab Al-Shiq) bridge",
    name_ar: "جسر باب مكحلة (باب الشق)",
    latitude: 15.17982527,
    longitude: 43.49228077,
    map: 12
  },
  {
    code: 19,
    name_en: "khaifah bridge",
    name_ar: "جسر خايفة",
    latitude: 15.673625,
    longitude: 43.543274,
    map: 12
  },
  {
    code: 20,
    name_en: "Al-Abarat bridge - (Al-Afrah bridge)",
    name_ar: "جسر العبرات - (جسر العفرة)",
    latitude: 16.298736,
    longitude: 43.790771,
    map: 12
  }
];

export const map5 = [
  {
    code: 21,
    name_en: "Sanaa",
    name_ar: "صنعاء",
    latitude: 15.359828,
    longitude: 44.1922583,
    map: 5
  },
  {
    code: 22,
    name_en: "Taiz",
    name_ar: "تعز",
    latitude: 13.5793146,
    longitude: 44.0136381,
    map: 5
  },
  {
    code: 23,
    name_en: "Al Hudaydah",
    name_ar: "الحديدة",
    latitude: 14.7900282,
    longitude: 42.929635,
    map: 5
  },
  {
    code: 24,
    name_en: "Aden",
    name_ar: "عدن",
    latitude: 12.810289,
    longitude: 44.9805477,
    map: 5
  },
  {
    code: 25,
    name_en: "Ibb",
    name_ar: "إب",
    latitude: 13.9593877,
    longitude: 44.1440534,
    map: 5
  },
  {
    code: 26,
    name_en: "Dhamar",
    name_ar: "ذمار",
    latitude: 14.5440286,
    longitude: 44.3866409,
    map: 5
  }
];

export const map14 = [
  "INF_00051",
  "INF_00586",
  "INF_00539",
  "INF_00415",
  "INF_00577",
  "INF_00180",
  "INF_00195",
  "INF_00273",
  "INF_00171",
  "INF_00160",
  "INF_00277",
  "INF_00355",
  "INF_00462",
  "INF_00208",
  "INF_00657",
  "INF_00521",
  "INF_00141",
  "INF_00368",
  "INF_00482",
  "INF_00323",
  "INF_00163",
  "INF_00172",
  "INF_00496",
  "INF_00200",
  "INF_00324",
  "INF_00593",
  "INF_00083",
  "INF_00520",
  "INF_00373",
  "INF_00137",
  "INF_00347",
  "INF_00513",
  "INF_00594"
];

export { map3, map4, map12 };
