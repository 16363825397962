import React from "react";
import styled from "@emotion/styled";
import { split, last } from "ramda";
import { jsx } from "@emotion/core";
import { Waypoint } from "react-waypoint";

export const BL = styled.br({
  marginBottom: "1.5rem !important",
});

const Text = styled.p({
  fontSize: "280%",
  color: "white",
});

const TextContainer = styled.div({
  position: "relative",
  maxWidth: "1000px",
  margin: "auto",
  padding: "2rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
});

function IntroScene({
  children,
  design,
  textContainerStyle,
  onEnter,
  onLeave,
}) {
  return (
    <Waypoint onEnter={onEnter} onLeave={onLeave}>
      <div css={{ height: "100vh", ...design }}>
        <TextContainer css={{ ...textContainerStyle }}>
          {children}
        </TextContainer>
      </div>
    </Waypoint>
  );
}

function Scene({
  children,
  design,
  textContainerStyle,
  onEnter,
  onLeave,
  bottomOffset,
  topOffset,
  isShown = true,
}) {
  return (
    <Waypoint
      onEnter={onEnter}
      onLeave={onLeave}
      topOffset={topOffset}
      bottomOffset={bottomOffset}
    >
      <div css={[{ height: "100vh" }, design]}>
        <TextContainer
          css={{
            height: "100%",
            width: "390px",
            display: "flex",
            /* justifyContent: "center", */
            /* alignItems: "center", */
            marginLeft: "4rem",

            ...textContainerStyle,
            paddingTop: 0,
          }}
        >
          <p
            css={{
              fontSize: "25px",
              fontWeight: 500,
              padding: "1rem 1rem",
              lineHeight: "1.4",
              opacity: isShown ? 1 : 0,
              backgroundColor: isShown ? "rgba(255,255,255,0.8)" : "opacity",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
              height: "max-content",
            }}
          >
            {children}
          </p>
        </TextContainer>
      </div>
    </Waypoint>
  );
}

function FullscreenScene({
  children,
  design,
  textContainerStyle,
  onEnter,
  onLeave,
  bottomOffset,
  topOffset,
}) {
  return (
    <>
      <Waypoint
        onEnter={onEnter}
        onLeave={onLeave}
        bottomOffset={bottomOffset}
        topOffset={topOffset}
      >
        <div css={{ height: "100vh", ...design }}>
          <TextContainer
            css={{
              height: "100%",
              backgroundColor: "unset",
              maxWidth: "1200px",
              fontSize: "1.6rem",
              margin: "auto",
              color: `rgba(255,255,255)`,
              lineHeight: "1.6",
              ...textContainerStyle,
            }}
          >
            {children}
          </TextContainer>
        </div>
      </Waypoint>
    </>
  );
}

function LoopedVideo({ src, ...attributes }) {
  const format = last(split(".", src));
  return (
    <video
      playsInline
      autoPlay
      muteesd
      loop
      {...attributes}
      css={{
        maxHeight: "500px",
      }}
    >
      <source src={src} type={`video/${format}`} />
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}

export function InlineIcon({ isRTL, icon, text }) {
  return (
    <div
      css={{
        textAlign: isRTL ? "right" : "left",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <img src={icon} width="25px" css={{ marginBottom: "0.7rem" }} />
      {text && (
        <p
          css={{
            marginLeft: "1rem",
            marginRight: "1rem",
            fontSize: "20px",
            fontWeight: "normal",
            marginBottom: "1rem",
          }}
        >
          {text}
        </p>
      )}
    </div>
  );
}

export { Scene, IntroScene, FullscreenScene, Text, LoopedVideo };
