import React, { useState, useEffect, useContext } from "react";
import { graphql, Link } from "gatsby";
import { LocaleContext } from "context/locale-context";
import SEO from "components/seo";
import LocalizedLink from "components/localizedLink";
import useTranslations from "components/hooks/useTranslations";
import YemeniMap from "../components/data/map";
import Collage from "../components/collage";
import { jsx } from "@emotion/core";
import style from "../normalize.css";
import {
  Scene,
  IntroScene,
  FullscreenScene,
  Text,
  LoopedVideo,
  BL,
  InlineIcon,
} from "../components/case-study";
import { colors } from "style/theme";
import { map2, map3, map4, map5, map14 } from "../../data/case-study";

function Index({ data }) {
  const locale = useContext(LocaleContext); // used in Graphql query
  const tr = useTranslations();
  const isRTL = locale === "ar";

  const collage = data.allFile.edges.map(
    (p) => p.node.childImageSharp.fluid.src
  );

  const allNodes = data.allIncidentsJson.edges.map((p) => p.node);
  const map8Nodes = allNodes.filter((node) => node.location === "Sana'a");
  const map11Nodes = allNodes.filter((node) => node.code === "INF_00051");
  const map12Nodes = allNodes.filter((node) => node.doubleTap);
  const map14Nodes = allNodes.filter((node) => map14.includes(node.code));
  const n1Nodes = allNodes.filter((node) => node.road === "N1");
  const n2Nodes = allNodes.filter((node) => node.road === "N2");
  const n3Nodes = allNodes.filter((node) => node.road === "N3");
  const n5Nodes = allNodes.filter((node) => node.road === "N5");

  const [opacity, setOpacity] = useState(0.3);
  const [skipVisibility, setSkipVisibility] = useState("none");
  const [dataLayer, setDataLayer] = useState(null);
  const [viewport, setViewport] = useState({ lat: 15.5, lng: 43, zoom: 7 });
  const [zIndex, setZIndex] = useState(-2);

  const [mainElement, setMainElement] = useState("video");
  const [backgroundImg, setBackgroundImg] = useState("man");
  const [backgroundVideo, setBackgroundVideo] = useState("output.webm");

  const [units, setUnits] = useState(null);
  const [mapNo, setMapNo] = useState(null);

  function map2Data() {
    let i = 0;
    const temp = [];
    Window.timer = setInterval(() => {
      let el = map2[i];
      if (units == undefined && i == 0) {
        setUnits([el]);
        temp.push(el);
      } else if (i < 10) {
        temp.push(el);
        setUnits([...temp, el]);
      }
      if (i >= 9) {
        clearInterval(Window.timer);
      }
      i++;
    }, 500);
  }

  return (
    <>
      <SEO metadata={{ title: tr("Yemeni Archive") }} />
      <div
        css={{
          position: "fixed",
          top: "90%",
          left: "90%",
          zIndex: 2,
          display: skipVisibility,
          flexDirection: "column",
          backgroundColor: "rgba(0, 0, 0, 0.53)",
          padding: "0.5rem",
          paddingTop: "0.65rem",
        }}
      >
        <LocalizedLink
          to="key-findings"
          css={{
            fontSize: "17px",
            position: "relative",
            color: "white",
            borderBottom: "1px solid #b32c50",
            transition: "1s",
            ":hover": {
              color: "#b32c50",
            },
          }}
        >
          {tr("Skip to Key Findings")}
        </LocalizedLink>
        <LocalizedLink
          to="data"
          css={{
            borderBottom: "1px solid #b32c50",
            fontSize: "17px",
            position: "relative",
            color: "white",
            textAlign: "center",
            paddingTop: "0.4rem",
            transition: "1s",
            ":hover": {
              color: "#b32c50",
            },
          }}
        >
          {tr("See the Data")}
        </LocalizedLink>
        <Link
          to={isRTL ? "/" : "ar"}
          css={{
            fontSize: "17px",
            position: "relative",
            color: "white",
            textAlign: "center",
            paddingTop: "0.4rem",
            transition: "1s",
            ":hover": {
              color: "#b32c50",
            },
          }}
        >
          {isRTL ? "English" : "عربي"}
        </Link>
      </div>
      <div
        css={{
          position: "relative",
          direction: isRTL ? "rtl" : "ltr",
          textAlign: isRTL ? "right" : "left",
        }}
      >
        {mainElement === "video" && (
          <video
            playsInline
            autoPlay
            muted
            loop
            poster="poster.jpg"
            css={{
              objectFit: "cover",
              height: "100vh",
              width: "100%",
              position: "sticky",
              top: 0,
              left: 0,
            }}
          >
            <source src={`/${backgroundVideo}`} type="video/webm" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        )}

        {mainElement === "map" && (
          <div
            css={{
              position: "sticky",
              top: "1vh",
              height: "100vh",
              boxSizing: "border-box",
              zIndex: zIndex,
            }}
          >
            <YemeniMap
              road={dataLayer}
              viewport={viewport}
              units={units}
              mapNo={mapNo}
              page="index"
            />
          </div>
        )}

        {mainElement === "image" && (
          <div
            css={{
              height: "100vh",
              width: "100%",
              position: "sticky",
              top: 0,
              left: 0,
              backgroundImage: `url(./${backgroundImg})`,
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              backgroundAttachment: "fixed",
              backgroundColor: "rgb(10,10,10)",
            }}
          ></div>
        )}

        <div
          css={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            transition: "1s",
            backgroundColor: `rgba(10, 10, 10, ${opacity})`,
          }}
        ></div>

        <IntroScene
          onEnter={() => {
            setBackgroundVideo("output.webm");
            setOpacity(0.15);
            setUnits(null);
            setDataLayer(null);
            setViewport({ lat: 15.5, lng: 43, zoom: 7 });
          }}
          design={{
            marginTop: "-40rem",
          }}
          textContainerStyle={{
            backgroundColor: "transparent",
            textAlign: "center",
          }}
        >
          <Text
            css={{
              fontSize: "48.83px",
              fontWeight: "bold",
              textTransform: "uppercase",
              paddingTop: "1rem",
              paddingBottom: "1.2rem",
              marginBottom: 0,
              borderBottom: `4px solid ${colors.primary}`,
            }}
          >
            {tr("Attacks on Access")}:
          </Text>
          <Text
            css={{
              fontSize: "48.83px",
              fontWeight: "bold",
              paddingTop: "1.4rem",
              paddingBottom: "1.4rem",
              borderBottom: `4px solid ${colors.primary}`,
            }}
          >
            {tr("Systematic Targeting of bridges in Yemen")}
          </Text>
        </IntroScene>

        <IntroScene
          onEnter={() => {
            setOpacity(0.45);
            setSkipVisibility("flex");
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(0.15);
              setUnits(null);
              setSkipVisibility("none");
              setDataLayer(null);
              setViewport({ lat: 15.5, lng: 43, zoom: 7 });
            }
          }}
          textContainerStyle={{
            textAlign: "center",
          }}
        >
          <Text>
            {tr(
              "Between 2015 and 2019, the Yemeni Archive has documented 142 attacks on bridges."
            )}
            <BL />
            {tr(
              "The damage blocks key transport routes for food and humanitarian aid."
            )}
          </Text>
        </IntroScene>

        <IntroScene
          onEnter={() => {
            setOpacity(0.75);
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              //setSkipVisibility("inline");
              setOpacity(0.45);
            }
          }}
          textContainerStyle={{
            textAlign: "center",
          }}
        >
          <Text>
            {tr(
              "More than a hundred civilians have been killed in these attacks."
            )}
            <BL />

            {tr(
              "Millions more face extreme hunger and are blocked from medical services."
            )}
          </Text>
        </IntroScene>

        <IntroScene
          onEnter={() => {
            setOpacity(1);
            setTimeout(() => setMainElement("map"), 1000);
            setSkipVisibility("flex");
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(0.6);
              setBackgroundVideo("output.webm");
              setMainElement("video");
            }
          }}
          textContainerStyle={{ backgroundColor: "unset", textAlign: "center" }}
        >
          <Text
            css={{
              color: "white",
              fontSize: "48.83px",
              fontWeight: "bold",
            }}
          >
            {tr("It is illegal under international law.")}
          </Text>
        </IntroScene>

        <Scene
          onEnter={() => {
            setViewport({ lat: 15.5, lng: 45, zoom: 7 });
            setMainElement("map");
            setOpacity(0);
            setUnits(null);
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(1);
            }
          }}
        >
          {tr(
            "Yemen has been in a civil conflict since 2014 when Houthi forces from the northwest gained control of the capital Sana’a and some other key cities"
          )}
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setDataLayer(null);
              setOpacity(0);
              setMainElement("map");
              map2Data();
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setDataLayer(null);
              clearInterval(Window.timer);
              setUnits(null);
              setOpacity(0);
              // setMainElement("map");
            }
          }}
          isShown={opacity < 0.5}
        >
          {<InlineIcon isRTL={isRTL} icon="./YA.png" text="City" />}
          {tr(
            "Houthi forces gained control of the capital Sana’a and expanded their territory in 2014-2015"
          )}
        </Scene>

        <FullscreenScene
          onEnter={() => {
            clearInterval(Window.timer);
            setUnits(null);
            setOpacity(1);
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setUnits(null);
              map2Data();
              setOpacity(0);
              setDataLayer(null);
            }
          }}
        >
          <div>
            <h2 css={{ fontSize: "1.8rem" }}>
              {tr(
                "The Yemeni government under president Hadi’s leadership requested support from Saudi Arabia."
              )}
            </h2>
            <h2 css={{ fontSize: "1.8rem" }}>
              {tr(
                "The Saudi-led coalition got permission from the UN security council to provide military support."
              )}
            </h2>

            <h2 css={{ fontSize: "1.8rem" }}>
              {tr(
                "The Saudi-led coalition was supposed to assist the government to help stabilize Yemen."
              )}
            </h2>
          </div>
        </FullscreenScene>

        <FullscreenScene
          onEnter={() => {
            clearInterval(Window.timer);
            setUnits(null);
            setOpacity(1);
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              clearInterval(Window.timer);
              setUnits(null);
              setOpacity(1);
            }
          }}
        >
          <h2>{tr("Since then, the country has been under siege.")}</h2>
        </FullscreenScene>

        <Scene
          onEnter={() => {
            clearInterval(Window.timer);
            setOpacity(0);
            setDataLayer(null);
            setUnits(null);
            setDataLayer("houthi2017");
            setUnits(map3);
            setViewport({ lat: 15, lng: 43, zoom: 8 });
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              //setDataLayer(null);
              setDataLayer(null);
              setUnits(null);
              setOpacity(1);
              setViewport({ lat: 15.5, lng: 43, zoom: 7 });
            }
          }}
        >
          {<InlineIcon isRTL={isRTL} icon="./port.png" text="Port" />}

          {tr(
            "Ports such as Al-Hudaydah and Aden are key infrastructure links for food, supplies, and humanitarian aid."
          )}
          <div css={{ marginTop: "1rem" }}>
            {<InlineIcon isRTL={isRTL} icon="./huthi.png" text="Huthi" />}
            {
              <InlineIcon
                isRTL={isRTL}
                icon="./government.png"
                text="Government"
              />
            }
          </div>
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition === "below") {
              setOpacity(0);
              setDataLayer(null);
              setDataLayer("houthi2019");
              setViewport({ lat: 13.6, lng: 44, zoom: 10 });
              setUnits([...map3, ...map4]);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(0);
              setDataLayer(null);
              setUnits(null);
              setDataLayer("houthi2017");
              setUnits(map3);
              setViewport({ lat: 15, lng: 43, zoom: 8 });
            }
          }}
          isShown={opacity < 0.5}
        >
          {tr(
            "The southern city of Ta’izz has been a key location along the front line"
          )}
        </Scene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition) {
              setDataLayer(null);
              setUnits(null);
              setOpacity(1);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setDataLayer("houthi2019");
              setViewport({ lat: 13.6, lng: 44, zoom: 10 });
              setUnits([...map3, ...map4]);
            }
          }}
        >
          <>
            <h2 css={{ fontWeight: "400", borderBottom: "2px solid #b32c50" }}>
              {tr(
                "Over the past six years, Saudi-led coalition warplanes have carried out 142 airstrikes on bridges in territory under control of the Houthi forces"
              )}
            </h2>
            <Collage images={collage} />
          </>
        </FullscreenScene>

        <FullscreenScene
          design={{ height: "30vh", width: "100%" }}
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setOpacity(1);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setDataLayer(null);
              setUnits(null);
              //              setOpacity(1);
            }
          }}
        />

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setViewport({ lat: 15.5, lng: 43, zoom: 8 });
              setDataLayer(null);
              setZIndex(0);
              setOpacity(0);
              setUnits(allNodes);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setDataLayer(null);
              setOpacity(1);
            }
          }}
        >
          {<InlineIcon isRTL={isRTL} icon="./Attacks.svg" text="Attack" />}
          {tr(
            "Many of these attacks block road networks between cities and ports, disrupting the transport of humanitarian aid and food supplies"
          )}
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setViewport({ lat: 15, lng: 45, zoom: 7 });
              setOpacity(0);
              setUnits(map5);
              setDataLayer(null);
              setDataLayer("allRoads");
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setViewport({ lat: 15.5, lng: 43, zoom: 8 });
              setDataLayer(null);
              setZIndex(0);
              setOpacity(0);
              setUnits(allNodes);
            }
          }}
        >
          {tr(
            "Many documented airstrikes targeted bridges along these key routes"
          )}
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setOpacity(0);
              setUnits(null);
              setDataLayer(null);
              setDataLayer("N1");
              setUnits(n1Nodes);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setViewport({ lat: 15, lng: 45, zoom: 7 });
              setDataLayer("allRoads");
              setOpacity(0);
              setUnits(map5);
              setDataLayer(null);
            }
          }}
        >
          <div css={{ marginTop: "1rem" }}>
            {
              <InlineIcon
                isRTL={isRTL}
                icon="./green-road.png"
                text="N1 Hightway"
              />
            }
            {<InlineIcon isRTL={isRTL} icon="./Attacks.svg" text="Attacks" />}
          </div>
          {tr(
            "Many attacks were along the N1 highway which links the southern port city of Aden to the Houthi-controlled region all the way to the northern border. Most food and supplies now come through Aden port, which is under forces loyal to the Saudi-led coalition control."
          )}
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setOpacity(0);
              setZIndex(0);
              setViewport({ lat: 15.5, lng: 44, zoom: 10 });
              setUnits(null);
              setUnits(map8Nodes);
              setDataLayer(null);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setViewport({ lat: 15, lng: 45, zoom: 7 });
              setOpacity(0);
              setUnits(null);
              setUnits(n1Nodes);
              setDataLayer(null);
              setDataLayer("N1");
            }
          }}
        >
          <div css={{ marginTop: "1rem" }}>
            {<InlineIcon isRTL={isRTL} icon="./Attacks.svg" text="Attacks" />}
          </div>
          {tr(
            "Attacks targeted bridges on roads leading in and out of the former capital Sana’a - which has a population of around 3 million people"
          )}
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setViewport({ lat: 15.35, lng: 43, zoom: 9 });
              setUnits([...n3Nodes, ...map2]);
              setDataLayer(null);
              setDataLayer("map9");
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(0);
              setZIndex(0);
              setViewport({ lat: 15.5, lng: 44, zoom: 10 });
              setUnits(null);
              setUnits(map8Nodes);
              setDataLayer(null);
            }
          }}
          isShown={opacity < 0.5}
        >
          {<InlineIcon isRTL={isRTL} icon="./Attacks.svg" text="Attack" />}
          {tr(
            "Bridges leading to port city Al-Hudaydah, which used to be a key source of food and supplies, were targeted with the aim of cutting off Houthi forces supply lines"
          )}
        </Scene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setBackgroundImg("background-truck2.jpg");
              setMainElement("image");
              setOpacity(0.5);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setMainElement("map");
              setOpacity(0);
              setViewport({ lat: 15.35, lng: 43, zoom: 9 });
              setUnits([...n3Nodes, ...map2]);
              setDataLayer(null);
              setDataLayer("map9");
            }
          }}
        >
          <div css={{ display: "flex" }}>
            <h2 css={{ marginTop: "4rem" }}>
              {tr(
                "A wheat truck destroyed during an attack on Lahimah bridge on September 15, 2015"
              )}
            </h2>
          </div>
        </FullscreenScene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setBackgroundImg("background-truck2.jpg");
              setMainElement("image");
              setOpacity(0.5);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setUnits(null);
              setViewport({ ...viewport, zoom: 8 });
              setDataLayer(null);
              setDataLayer("N1");
            }
          }}
        >
          <h2>
            {tr(
              "As of December 2019, the UN said 15.9 million people were severely food insecure. That’s more than half the country’s population. Another 3 million were malnourished."
            )}
          </h2>
        </FullscreenScene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setOpacity(1);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(0.5);
              setMainElement("image");
              setBackgroundImg("background-truck2.jpg");
            }
          }}
        >
          <h2>“{tr("Conflict continues to drive hunger in Yemen.")}”</h2>
        </FullscreenScene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setDataLayer(null);
              setOpacity(1);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(1);
            }
          }}
        >
          <h2 css={{ textAlign: "center" }}>
            {tr("The Yemeni Archive has investigated three key attacks")}
          </h2>
          {opacity === 1 && (
            <div
              css={{
                display: "flex",
                maxWidth: "1000px",
                justifyContent: "space-between",
                margin: "auto",
              }}
            >
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://yemeniarchive.org/${locale}/investigations/Multiple-attacks-on-Lahmah-Bridge-in-Al-Mahwit-Governorate`}
              >
                <LoopedVideo src="/bridge01.mp4" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://yemeniarchive.org/${locale}/investigations/Attacks-on-Khaifa-Bridge-in-Hajjah-governorate`}
              >
                <LoopedVideo src="/bridge02.mp4" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://yemeniarchive.org/${locale}/investigations/Daleel`}
              >
                <LoopedVideo src="/bridge03.mp4" />
              </a>
            </div>
          )}
        </FullscreenScene>

        <FullscreenScene
          design={{ height: "30vh", width: "100%" }}
          onEnter={() => setOpacity(1)}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setDataLayer(null);
              setOpacity(1);
            }
          }}
        />

        <Scene
          onEnter={() => {
            setMainElement("map");
            setOpacity(0);
            setViewport({ lat: 15, lng: 43, zoom: 8 });
            setUnits(map11Nodes);
            setZIndex(0);
            setDataLayer(null);
            setDataLayer("N1");
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              //setDataLayer(null);
              setUnits(null);
              setOpacity(1);
              //setMainElement("image");
            }
          }}
          isShown={mainElement !== "image"}
        >
          <div css={{ marginTop: "1rem" }}>
            {
              <InlineIcon
                isRTL={isRTL}
                icon="./green-road.png"
                text="N1 Hightway"
              />
            }
            {
              <InlineIcon
                isRTL={isRTL}
                icon="./YA.png"
                text="Al-Dalil bridge"
              />
            }
          </div>
          {tr(
            "The Al-Dalil bridge is on the N1. It connects the Ibb region to the southern port of Aden and to the Saudi border in the north."
          )}
        </Scene>

        <IntroScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setBackgroundImg("background-road.jpg");
              setMainElement("image");
              setOpacity(0);
              //            setZIndex(-1);
              setDataLayer(null);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setMainElement("map");
              setOpacity(0);
              setViewport({ lat: 15, lng: 43, zoom: 8 });
              setUnits(map11Nodes);
              setZIndex(0);
              setDataLayer(null);
              setDataLayer("N1");
            }
          }}
        >
          <Text>
            {tr(
              "On April 21, 2015, during the day, an airstrike hit the bridge."
            )}
          </Text>
        </IntroScene>

        <IntroScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setOpacity(0);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setBackgroundImg("background-road.jpg");
              setMainElement("image");
              setOpacity(0);
              //            setZIndex(-1);
              setDataLayer(null);
            }
          }}
        >
          <Text>
            {tr(
              "Yemeni Archive verified videos showing dozens of injured at the scene."
            )}
          </Text>
        </IntroScene>

        <IntroScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setBackgroundVideo("bridge.webm");
              setMainElement("video");
              setOpacity(0.3);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(1);
              setMainElement("image");
            }
          }}
        >
          <Text>{tr("The damage from the airstrike blocked the road")}</Text>
        </IntroScene>

        <IntroScene>
          <Text>
            {tr(
              "It impeded civilian access to hospitals, markets and neighbouring communities"
            )}
          </Text>
        </IntroScene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setMapNo("map12");
              setMainElement("map");
              setOpacity(0);
              setUnits(map12Nodes);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setBackgroundVideo("bridge.webm");
              setMainElement("video");
              setOpacity(0.3);
            }
          }}
          isShown={opacity < 0.5}
        >
          {<InlineIcon isRTL={isRTL} icon="./Bridges.svg" text="Bridge" />}
          {tr(
            "Yemeni Archive documented five times bridges were ‘double tapped.’ Shortly after a first airstrike, there was a second. Often, it came once rescuers arrived to help the wounded."
          )}
        </Scene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setMapNo(null);
              //              setZIndex(-1);
              //              setDataLayer("N1");
              setOpacity(1);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setMapNo("map12");
              setMainElement("map");
              setOpacity(0);
            }
          }}
        >
          <h1>{tr("Al-Dalil was one of them")}.</h1>
        </FullscreenScene>

        <FullscreenScene
          onEnter={() => {
            setOpacity(1);
          }}
          onLeave={() => {
            //setOpacity(1);
            //setDataLayer("N1");
            //setZIndex(-1);
          }}
        >
          {isRTL ? (
            <div>
              <img src="./un-report-ar-2.png" width="818px" />
              <img src="./un-report-ar.png" />
            </div>
          ) : (
            <div>
              <img src="./un-report-2.png" width="800px" />
              <img src="/un-report.png" />
            </div>
          )}
          <p>{tr("Source: UN report")}</p>
        </FullscreenScene>

        <IntroScene
          onEnter={() => {
            setBackgroundImg("background-man.png");
            setMainElement("image");
            setOpacity(0);
          }}
          onLeave={() => {
            //            setOpacity(1);
          }}
          textContainerStyle={{ marginTop: "15rem" }}
        >
          <Text>
            {tr("bassam-text-p1")}
            <br />
            <span
              css={{
                fontSize: "40%",
                textAlign: "right",
                display: "block",
                marginTop: "2rem",
              }}
            >
              {tr(
                "The brother of one of the victims of the attack on Al-Dalil bridge"
              )}
            </span>
          </Text>
        </IntroScene>
        <IntroScene
          onEnter={() => {
            setBackgroundImg("background-man.png");
            setMainElement("image");
            setOpacity(0);
          }}
          onLeave={() => {
            //            setOpacity(1);
          }}
        >
          <Text>{tr("bassam-text-p2")}</Text>
        </IntroScene>
        <IntroScene
          onEnter={() => {
            setBackgroundImg("background-man.png");
            setMainElement("image");
            setOpacity(0);
          }}
          onLeave={() => {
            //          setOpacity(1);
          }}
        >
          <Text>{tr("bassam-text-p3")}</Text>
        </IntroScene>
        <IntroScene
          onEnter={() => {
            setBackgroundImg("background-man.png");
            setMainElement("image");
            setOpacity(0);
          }}
          onLeave={() => {
            //          setOpacity(1);
          }}
        >
          <Text>{tr("bassam-text-p4")}</Text>
        </IntroScene>

        <FullscreenScene
          onEnter={() => {
            setOpacity(1);
          }}
        >
          <div>
            <p>
              {tr(
                "It is illegal to target civilian infrastructure and to block access to humanitarianassistance under international humanitarian and human rights law."
              )}
            </p>
            <p>
              {tr(
                "The coalition claims bridges are military objects, since they are used by Houthi forces."
              )}
            </p>
            <p>
              {tr(
                "But even if that’s the case, international law requires military forces to consider the impact on civilians."
              )}
            </p>
            <details css={{ fontSize: "65%" }}>
              <summary css={{ cursor: "pointer" }}>
                {tr("More about the legal implications")}
              </summary>
              {tr(
                "Under Customary International Law, which is binding on all States, it is unlawful to attack civilian objects, that is, objects which are not military objectives. It is also unlawful to attack objects indispensable for the survival of the civilian population and to use starvation as a method of warfare."
              )}
              <p>
                {tr(
                  "Intentionally attacking civilian objects and launching attacks that cause disproportionate civilian harm are war crimes."
                )}
              </p>
              <p>
                {tr(
                  "Customary International Law also requires parties to a conflict to allow and facilitate rapid and unimpeded passage of humanitarian relief."
                )}
              </p>
              <p>
                {tr(
                  "Civilian bridges and buildings are among the objects covered by international protection in international humanitarian law, due to their utmost importance for civilians in areas of armed conflict (see Article 52 of Additional Protocol I).If a bridge is vital for the transportation of food and medicine for civilians, it could additionally be considered an object indispensable to the survival of the civilian population, which cannot be attacked under Customary International Law and destroying it may indicate the war crime of starvation."
                )}
              </p>
              <p>
                {tr(
                  "Even if a bridge is being used by the military, the consequences of its destruction for the civilian population must be weighed against the military advantage expected from the destruction of the bridge."
                )}
              </p>
              <p>
                {tr(
                  "The fact that so many Yemenis in Houthi controlled territory were already starving or at risk of starvation strongly suggests that either the Coalition intended to increase the starvation or, at the very least, did not consider the effects of the destruction of this infrastructure on the civilian population"
                )}
              </p>
            </details>
          </div>
        </FullscreenScene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setMainElement("map");
              setUnits(allNodes);
              setOpacity(0);
              setDataLayer(null);
              setMapNo("map13");
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(1);
              setDataLayer(null);
            }
          }}
        >
          {<InlineIcon isRTL={isRTL} icon="./Attacks.svg" text="Direct Hit" />}
          {
            <InlineIcon
              isRTL={isRTL}
              icon="./non-direct.png"
              text="Non Direct Hit"
            />
          }
          {tr(
            "Of the 142 documented attacks on bridges, all but a single one was a direct hit."
          )}
        </Scene>

        <Scene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setMapNo(null);
              setUnits(map14Nodes);
              setOpacity(0);
              setDataLayer(null);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setMainElement("map");
              setMapNo("map13");
              setUnits(allNodes);
              setOpacity(0);
              setDataLayer(null);
            }
          }}
          isShown={opacity < 0.5}
        >
          {
            <InlineIcon
              isRTL={isRTL}
              icon="./Attacks.svg"
              text="Attack with casualties"
            />
          }
          {tr(
            "At least 33 attacks are reported to have resulted in casualties. Yemeni Archive was able to document more than 120 people who were killed as a result of these attacks."
          )}
        </Scene>

        <FullscreenScene
          //          bottomOffset="-200px"
          onEnter={() => {
            setOpacity(1);
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setMainElement("map");
              setOpacity(0);
              setDataLayer(null);
              setUnits(map14Nodes);
            }
          }}
        >
          <h2>{tr("Sabri Qasim Al-Naqeb was one of them.")}</h2>
        </FullscreenScene>

        <IntroScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setBackgroundImg("background-woman.png");
              setMainElement("image");
              setOpacity(0);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(1);
            }
          }}
        >
          <Text>{tr("woman-text-p1")}</Text>
        </IntroScene>

        <IntroScene
          onEnter={() => {
            /* setBackgroundImg("background-woman.png"); */
            /* setMainElement("image"); */
            /* setOpacity(0); */
          }}
        >
          <Text>{tr("woman-text-p2")}</Text>
        </IntroScene>

        <IntroScene
          onEnter={() => {
            /* setBackgroundImg("background-woman.png"); */
            /* setMainElement("image"); */
            /* setOpacity(0); */
          }}
        >
          <Text>{tr("woman-text-p3")}</Text>
        </IntroScene>

        <FullscreenScene
          onEnter={({ previousPosition }) => {
            if (previousPosition == "below") {
              setOpacity(1);
            }
          }}
          onLeave={({ currentPosition }) => {
            if (currentPosition == "below") {
              setOpacity(0);
            }
          }}
        >
          <p>{tr("To read the complete research, go to")}:</p>
          <LocalizedLink
            to="key-findings"
            css={{
              color: "white",
              ":hover": {
                color: "#b32c50",
              },
            }}
          >
            {tr("Key Findings")}
          </LocalizedLink>
          <br />
          <LocalizedLink
            to="data"
            css={{
              color: "white",
              color: "white",
              ":hover": {
                color: "#b32c50",
              },
            }}
          >
            {tr("The Data")}
          </LocalizedLink>
          <br />
          <a
            href={`https://yemeniarchive.org/${locale}/investigations`}
            rel="noopener noreferrer"
            target="_blank"
            css={{
              color: "white",
              ":hover": {
                color: "#b32c50",
              },
            }}
          >
            {tr("Investigations")}
          </a>
        </FullscreenScene>
      </div>
    </>
  );
}

export default Index;

export const pageQuery = graphql`
  query Index {
    allFile(filter: { name: { regex: "/collage/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 70, maxWidth: 2000) {
              src
              originalName
              originalImg
            }
          }
        }
      }
    }
    allIncidentsJson {
      edges {
        node {
          id
          code
          road
          latitude
          doubleTap
          longitude
          name_ar
          name_en
          location
        }
      }
    }
  }
`;
