import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const ImgBox = styled.div({
  border: "solid 2px black",
  padding: "5px",
  boxShadow: "5px 5px 5px rgba(0,0,0,0.5)",
  borderRadius: "5px",
  transition: "all .25s ease-in-out",
  backgroundColor: "white",
  marginBottom: "1rem",
  height: "100%",
  "& img": {
    filter: "grayscale(100)",
    objectFit: "cover",
    minHeight: "100%",
    borderRadius: "5px",
    transition: "all .25s ease-in-out",
    ":hover": {
      filter: "grayscale(0)",
      cursor: "pointer"
    }
  }
});

const Column = styled.div({
  display: "flex",
  flexDirection: "column",
  marginRight: "0.5rem"
});

function Img({ src, boxStyle }) {
  return (
    <ImgBox css={boxStyle}>
      <a href={`${src}`} rel="noopener noreferrer" target="_blank">
        <img src={src} css={{}} />
      </a>
    </ImgBox>
  );
}

function Collage({ images }) {
  return (
    <>
      <div
        css={{
          display: "flex"
        }}
      >
        <Column>
          <Img src={images[0]} />
          <Img src={images[3]} boxStyle={{ alignSelf: "flex-end" }} />
        </Column>
        <Column>
          <Img src={images[2]} />
          <Img src={images[1]} />
        </Column>
        <Column>
          <Img src={images[4]} />
          <Img src={images[5]} boxStyle={{ flexBasis: "200px" }} />
        </Column>
        <Column>
          <Img src={images[6]} />
          <Img src={images[7]} />
        </Column>
      </div>
    </>
  );
}

Collage.propTypes = {
  images: PropTypes.array
};

export default Collage;
